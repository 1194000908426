/**
 * Aqui se encontraran los metodos globales para el proyecto.
 */
const Methods = {
    install(Vue, options) {
        /**
         * Este metodo cambia el formato para las fechas.
         * @param {Recibe una string con un formato de fecha} oldDate 
         */
        Vue.prototype.$cambiarFormatoDate = function (oldDate) {
            let date = new Date(oldDate); //Convertimos el string a formato Date
            return date.toLocaleString('es-MX', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'America/Tijuana'
            }); //Regresamos la nueva fecha con el nuevo formato
        }

        Vue.prototype.$onlyNumber = function (keyCodeEvent, which) {
            let keyCode = keyCodeEvent ? keyCodeEvent : which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
              // 46 is dot
              return true;
            }
            return false
          },

        Vue.prototype.$obtenerMaterialesAPI = function () {
            let materiales = fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.$store.state.cuenta.token
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(materiales => {
                    return materiales;
                })
                .catch(err => {
                    return err;
                });

            return materiales;
        }
    }
};

export default Methods;