<template>
  <v-app>
    <v-app-bar app height="40px" :color="colorDinamico" v-if="sizeScreen > 600">
      <!-- <v-btn color="#20419A" icon @click="top">
        <v-icon>mdi-menu</v-icon>
      </v-btn> -->
      <v-spacer></v-spacer>
      <a text href="#about">
        <h3 class="a__nav">Acerca de</h3>
      </a>
      <a text c href="#contact">
        <h3 class="a__nav">Contacto</h3>
      </a>
    </v-app-bar>

    <v-app-bar app height="40px" :color="colorDinamico" v-else>

    </v-app-bar>

    <v-main>
      <section id="header">
        <div class="header__content white--text">
          <v-img :src="require('@/assets/header.jpg')"></v-img>
          <div class="header__content__inner">
            <h1 class="header__content__title"><em>PRESTA</em></h1>
            <hr class="hr" />
            <p class="header__content__p">
              Aplicación del Laboratorio de Computación para solicitar material en préstamo.
            </p>          
            <GoogleLogin />
          </div>
        </div>
      </section>

      <section id="about">
        <div class="about__content">
          <div class="white--text">
            <h2 class="about__content__title">Acerca de <em>Presta</em></h2>
            <hr class="hrWhite" />
            <p class="about__content__p">
              <em>Presta</em> es una aplicación de préstamos de material de laboratorio, 
              donde maestros y alumnos pueden solicitar materiales, acceder a sus préstamos 
              pendientes y cancelar solicitudes.
              <br /> 
              Accesible desde cualquier dispositivo conectado a Internet.
            </p>
          </div>
        </div>
      </section>

      <section id="contact">
        <div class="contact__info">
          <h2 class="contact__title">¡Contacto!</h2>
          <hr class="hr" />
          <div class="contact__p">
            Si encuentras algún error o tienes alguna recomendación de mejora, envía un mensaje
            <div class="a__nav">
              <a
                
                href="mailto:molguin@uabc.edu.mx?subject=Presta&body=<Escribe aquí tus dudas y/o sugerencias>"
                >
            <v-icon size="50">$gMail</v-icon>
            </a>
          </div>
          </div>
        </div>
       
      </section>

      <v-footer dark>
        <v-container>
          <v-row>
            <v-spacer class="hidden-sm-and-down" />

            <v-col cols="12" md="auto">
              <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
                <b><em>Presta</em></b>
                &copy; 2023, hecho en
                <v-icon>$custom</v-icon>por la carrera de
                <a
                  href="https://ingenieria.mxl.uabc.mx/pe_ico/"
                  style="color: green;"
                >Ingeniero en Computación</a>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import GoogleLogin from './components/BtnGoogleLogin.vue'

export default {
  name: "VistaInicial",
  data() {
    return {
      items: [
        { title: "Acerca de", href: "#about" },
        { title: "Contacto", href: "#contact" },
      ],
      colorDinamico: "white",
      sizeScreen: 0,
    };
  },
  components: {
    GoogleLogin
  },
  created() {
    this.sizeScreen = window.innerWidth
    window.addEventListener("resize", this.myEventSize)
  },
  computed: {
    options() {
      return {
        duration: 800,
        offset: 0,
        easing: "easeInOutQuad",
      };
    },
  },
  mounted() {
  },
  methods: {
    top() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    myEventSize() {
      this.sizeScreen = window.innerWidth;
    },
    handlerMenu(item) {
    },
  },
};
</script>

<style>

#header {
  color: white;
  font-style: normal;
  background-size: contain;
}

.header__content {
  position: relative;
}

.header__content__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  text-align: center;
}

.header__content__title {
  text-align: center;
  font-size: 4vw;
}

.header__content__p {
  text-align: center;
  font-size: 2dvw;
}

#about {
  background-color: #20419a;
  text-align: center;
  height: 35em;
}

.about__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.about__content__title {
  text-align: center;
  font-size: 3vw;
}

@media screen and (min-width: 601px) {
  .about__content__p {
    text-align: center;
    font-size: 1.5vw;
    padding: 0 13vw;
  }
}

@media screen and (max-width: 600px) {
  .about__content__p {
    text-align: center;
    padding: 0 13vw;
  }
}

#contact {
  background-color: white;
  text-align: center;
}

.contact__info {
  padding: 2em 2em;
}

@media screen and (min-width: 601px) {
  .contact__title {
    font-size: 3vw;
    text-align: center;
  }

  .contact__p {
    font-size: 1.5vw;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .contact__title {
    font-size: 5vw;
    text-align: center;
  }
  .contact__p {
    text-align: center;
  }
}

.creadores {
  text-align: center;
}

.v-application a {
  color: black;
  text-decoration: none;
}

.a__nav {
  text-align: center;
  color: #20419a;
  font-size: 15px;
  margin: 0 0.3vw;
  padding: 2px;
  border: 0px solid blue;
  border-color: black;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.a__nav:hover {
  box-shadow: 5px 10px 18px black;
}

.a__nav__movil {
  color: black;
  font-size: 15px;
}

.btn-google {
  background-color: #20419a;
  width: 14vw;
  height: 4vw;
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5vw;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.btn-google:hover {
  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.24),
    0 20px 50px 0 rgba(0, 0, 0, 0.19);
}

.hr {
  margin: 5px auto;
  border-color: #1c44b0;
  background-color: #1c44b0;
  height: 4px;
  max-width: 50px;
}

.hrWhite {
  margin: 5px auto;
  border-color: white;
  background-color: white;
  height: 4px;
  max-width: 50px;
}

.nav__normal {
  position: fixed;
  top: 0%;
  width: 100%;
  overflow: hidden;
  float: right;
}

/* .btn_google {
  display: flex;
  justify-content: center;
} */
</style>
