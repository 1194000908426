import Vue from 'vue'
import Router from 'vue-router'
import store from './store';
import VistaLogin from '@/views/login/VistaLogin.vue';
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/login'
    },
    {
      name: 'Administrador',
      path: '/Administrador/:id',
      props: true,
      component: () => import('@/views/administrador/Index'),
      meta: {
        requiresAuth: true,
        permiso: 'Administrador'
      },
      children: [
        {
          name: 'PerfilUsuarioAdmin',
          path: 'Pages/Perfil',
          component: () => import('@/views/administrador/pages/UserProfile'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        },
        {
          name: 'SolicitudesPrestamosAdmin',
          path: 'Pages/Solicitudes',
          component: () => import('@/views/administrador/pages/SolicitudPrestamos.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        },
        {
          name: 'DevolucionesPrestamosAdmin',
          path: 'Pages/Devoluciones',
          component: () => import('@/views/administrador/pages/Devoluciones.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        },
        {
          name: 'PaquetesPublicosAdmin',
          path: 'Pages/PaquetesPublicos',
          component: () => import('@/views/administrador/pages/Paquetes.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        }, {
          name: 'Historial',
          path: 'Pages/Historial',
          component: () => import('@/views/administrador/pages/Historial.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        },
        {
          name: 'MaterialesAdmin',
          path: 'Pages/Administrar/Materiales',
          component: () => import('@/views/administrador/pages/Editors/EditarCategorias.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        },
        // {
        //   name: 'carreraAdmin',
        //   path: 'Pages/Administrar/Carreras',
        //   component: () => import('@/views/administrador/pages/Editors/EditarCarreras.vue'),
        //   meta: {
        //     requiresAuth: true,
        //     permiso: 'Administrador'
        //   },
        // },
        {
          name: 'cuentasAdmin',
          path: 'Pages/Administrar/Cuentas',
          component: () => import('@/views/administrador/pages/Editors/EditarCuentas.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        },
        {
          name: 'tutoresAdmin',
          path: 'Pages/Administrar/Tutores',
          component: () => import('@/views/administrador/pages/Editors/EditarTutores.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Administrador'
          },
        }
      ],
    },
    {
      name: 'Usuario',
      path: '/Usuario/:id',
      props: true,
      component: () => import('@/views/alumno/Index'),
      meta: {
        requiresAuth: true,
        permiso: 'Alumno'
      },
      children: [
        // Pages

        {
          name: 'perfilUsuarioAlumno',
          path: 'Page/Perfil',
          component: () => import('@/views/alumno/pages/PerfilUsuario.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Alumno'
          },
        },
        {
          name: 'crearPrestamo',
          path: 'Pages/Solicitudes',
          component: () => import('@/views/alumno/pages/GenerarPrestamos.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Alumno'
          },
        },
        {
          name: 'consultarMisPrestamos',
          path: 'Pages/MisPrestamos',
          component: () => import('@/views/alumno/pages/ConsultarPrestamos.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Alumno'
          }
        },
        {
          name: 'paquetesPublicos',
          path: 'Pages/PaquetesPublicos',
          component: () => import('@/views/alumno/pages/PaquetePublicos.vue'),
          meta: {
            requiresAuth: true,
            permiso: 'Alumno'
          }
        }
      ]
    },
    {
      name: 'login',
      path: '/login',
      component: VistaLogin,
      meta: {
        requiresAuth: false
      }
    },
    {
      name: 'Registro',
      path: '/Usuario/:id/Pages/VerificarCuenta',
      component: () => import('@/views/alumno/pages/VerificarCuenta.vue'),
      meta: {
        primeraVez: true,
        requiresAuth: true,
        permiso: 'Alumno'
      },
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const tipoCuenta = store.getters.getCuenta;
    if (tipoCuenta == 'Administrador' || tipoCuenta == 'Responsable') {
      if (to.meta.permiso == tipoCuenta) {
        next();
      } else if (to.name != 'MaterialesAdmin' && to.name != 'carreraAdmin' && to.name != 'cuentasAdmin' && to.name != 'tutoresAdmin') {
        next();
      } else {
        next(from.fullPath);
      }
    } else if (tipoCuenta == 'Alumno' || tipoCuenta == 'Maestro') {
      if (to.meta.primeraVez == true) {
        next();
      } else if (to.meta.permiso == 'Alumno') {
        next();
      } else {
        next(from.fullPath);
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
})

export default router;