<template>
    <div>
        <div class="btn_google" ref="googleLoginBtn"></div>
    </div>
</template>

<script>
export default {
  name: "GoogleLogin",
  data() {
    return {
    };
  },
  mounted(){

      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          type: 'standard',
          /* text: 'signin_with', // or 'signup_with' | 'continue_with' | 'signin'
          size: 'large', // or 'small' | 'medium'
          width: '366', // max width 400
          theme: 'outline', // or 'filled_black' |  'filled_blue'
          logo_alignment: 'center' // or 'center' */
        }
      )
  },
  created() {
    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_ID_CLIENT_GOOGLE,
        callback: this.onSuccess,
        context: 'signin',
        auto_select: false,
        scope: process.env.VUE_APP_GOOGLE_SCOPES,
        referrerPolicy: {
          policy: 'strict-origin-when-cross-origin'
        }
      })

      window.google.accounts.id.renderButton(
        this.$refs.googleLoginBtn, {
          type: 'standard',
          /* text: 'signin_with', // or 'signup_with' | 'continue_with' | 'signin'
          size: 'large', // or 'small' | 'medium'
          width: '366', // max width 400
          theme: 'outline', // or 'filled_black' |  'filled_blue'
          logo_alignment: 'left' // or 'center' */
        }
      )
      window.google.accounts.id.cancel();
      window.google.accounts.id.prompt();
    }
  },
  methods: {
    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    async onSuccess(googleUser) {
      const user = this.parseJwt(googleUser.credential)
      const name = user.name
      const imgURL = user.picture
      const email = user.email
      const id_token = googleUser.credential
      this.$store //store.js
        .dispatch("login", { name, imgURL, email, id_token })
        .then((cuenta) => {
          if (cuenta == null) {
            this.$alert(
              "Se detecto una nueva solicitud por " + data,
              "Nuevo Prestado",
              "warning"
            ).then(() => console.log("Closed"));
          } else {
            if (cuenta.primeraConexion == true) {
              this.$router.push(
                "/Usuario/" +
                  cuenta.matricula +
                  "/Pages/VerificarCuenta"
              );
            } else {
              if (cuenta.tipo == "Alumno" || cuenta.tipo == "Maestro") {
                this.$router.push(
                  "/Usuario/" + cuenta.idCuenta + "/Pages/Solicitudes"
                );
              } else {
                this.$router.push(
                  "/Administrador/" +
                    cuenta.idCuenta +
                    "/Pages/Solicitudes"
                );
              }
            }
          }
        })
        .catch((err) => console.log(err));
    },
  }
}
</script>
<style>
.btn_google {
  display: flex;
  justify-content: center;
}
</style>