import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
// eslint-disable-next-line import/no-duplicates
import vuetify from './plugins/vuetify'
import i18n from './i18n'
// eslint-disable-next-line import/no-duplicates
import './plugins/vuetify'
import MethodsPlugin from './plugins/MethodsProyect'
import VueSocketIO from 'vue-socket.io'
import VueSimpleAlert from 'vue-simple-alert'

Vue.config.productionTip = false

Vue.use(MethodsPlugin) // Le decimos que use los metodos globales creados por nosotros
Vue.use(VueSimpleAlert) // Es un plugin para las alert
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_URL_SOCKETS_BACKEND,
  vuex: {
    store,
  },
}),
)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  sockets: {
    connect: function () {
    },
    nuevaSolicitud: function (data) {
      if (store.getters.getCuenta === undefined) {
        return
      }
      if (store.getters.getCuenta === 'Alumno' || store.getters.getCuenta === 'Maestro') {
        return
      }
      const myTrack = new Audio(require('./assets/audio.mp3'))
      myTrack.play();
      this.$alert(
        'Se detecto una nueva solicitud por ' + data,
        'Nuevo Préstamo',
        'success',
      ).then(() => console.log('Closed'))
    },
  },
  render: h => h(App),
}).$mount('#app')
