import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barImage: '',
    drawer: false,
    cuenta: {},
    solicitudes: []
  },
  getters: {
    isLoggedIn: state => !!state.token,
    getCuenta: state => state.cuenta.tipo,
    getSolicitudes: state => state.solicitudes
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    logout(state) {
      state.cuenta = {}
    },
    guardarDatos(state, cuenta) {
      state.cuenta = cuenta
    },
    agregarSolicitud(state,solicitud) {
      state.solicitudes.shift(solicitud)
    }
  },
  actions: {
    login: async function ({ commit }, user) {
      const data = await fetch(process.env.VUE_APP_URL_BACKEND + '/login', {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: user.name,
          imgURL: user.imgURL,
          email: user.email,
          id_token: user.id_token,
        })
      }).then(response => {
        return response.json();
      }).then(cuenta => {
        return cuenta;
      }).catch(() => {
        commit('logout')
      });
      const token = await data.token;
      let cuenta = null;
      if (data.primeraConexion == true) {
        cuenta = data.cuenta;
        cuenta["image"] = user.imgURL
        cuenta["token"] = data.token;
        cuenta["tipo"] = data.tipo;
        cuenta["primeraConexion"] = data. primeraConexion;
      } else {
        cuenta = data.cuenta;
        cuenta["image"] = user.imgURL
        cuenta["token"] = data.token;
        cuenta["primeraConexion"] = data.primeraConexion;
      }
      commit('guardarDatos', cuenta);
      localStorage.token = cuenta.token;
      return cuenta;
    },//login
    logout({ commit }) {
      
      //return await new Promise((resolve, reject) => {
       /*  window.google.accounts.id.revoke(this.state.cuenta.correo, done => {
           console.log('Consentimiento revocado');
        }) */
        commit('logout')
        localStorage.removeItem('token');
        localStorage.removeItem('cuenta');
        return
        //resolve()
      //})
    }
  },
})
