import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import { Ripple } from 'vuetify/lib/directives'; 
import '@/sass/overrides.sass'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

const theme = {
  primary: '#565A5C',
  secondary: '#565A5C',
  black: '#000000',
  accent: '#9C27b0',
  info: '#1B40A6',
  verdeBoton:'#50B848',
  drawerColorSecondary: '#20419A',
  drawerColorFooter: '#1B40A6',
  elementoColor: '#565A5C',
  colorLetraDrawer: '#F2F2F2',
}

import CustomIcon from '../assets/uabcLogo.vue'
import IconGmail from '../assets/gmail.vue'
import IconPhone from '../assets/phone.vue'

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    values: {
      custom: {
        component: CustomIcon
      },
      gMail: {
        component: IconGmail
      },
      phone: {
        component: IconPhone
      }
    },
  },
})
